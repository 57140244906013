/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import "./prescriptions-page.scss";
import { RecipeController } from "../../../api/requests/recipeController";
import { PrescriptionRequestDto, initFormValues } from "./prescriptions-form-tools";
import { useFieldArray, useForm } from "react-hook-form";
import CheckBoxInput from "./CheckBox/CheckBox";
import TextInput from "./TextBox/TextInput";
import Col from "../../components/grid/col";
import Row from "../../components/grid/row";

const PrescriptionsForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<PrescriptionRequestDto>({
    defaultValues:{
      prescriptions: [{
        dose: "",
        name: "",
        pillsCountInPackage: "",
        packagesCount: "",
      }]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "prescriptions", // Nazwa pola z tablicą obiektów
  });

  const isAcceptedSelfRecive = watch("customer.isAcceptedSelfRecive");
  const isAcceptedSmsRecive = watch("customer.isAcceptedSmsRecive");
  const prescriptions = watch("prescriptions");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validationActive, setValidationActive] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [callbackActive, setCallbackActive] = useState(false);

  const onSubmit = (data: PrescriptionRequestDto) => {
    setValidationActive(false);
    setCallbackActive(true);

    //promise result
    var promise = RecipeController.send(data);
    promise
      .then((res: boolean) => {
        if (res) {
          reset(initFormValues);
          setCallbackActive(false);
        } else {
          setCallbackActive(false);
        }
      })
      .catch((error) => setCallbackActive(false));
  };

  // setTimeout(() => {
  //   const element = document.getElementsByClassName('red')[0];

  //   (element as HTMLElement)?.focus();
  //   let headerHeight = 0;
  //   let navHeight = document.querySelector(".navbarBarWrapper");
  //   if (navHeight) headerHeight = navHeight.clientHeight;
  //   element.scrollIntoView();
  //   window.scrollBy(0, -(headerHeight + 40))

  //   setValidationActive(false);
  // }, 0);

  // const handleClick = (e: any) => {
  //   e.stopPropagation();
  // }

  // const handleKeypress = (e: KeyboardEvent) => {
  //   if (e.keyCode === 13) {
  //     onSubmit()
  //   }
  // }

  return (
    <div className="prescriptions-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gap={16}>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.firstName"
              label="Imię"
              validation_msg={errors?.customer?.firstName?.message}
              required
              inputProps={{
                ...register("customer.firstName", { required: "Pole jest wymagane" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.lastName"
              label="Nazwisko"
              validation_msg={errors?.customer?.lastName?.message}
              required
              inputProps={{
                ...register("customer.lastName", { required: "Pole jest wymagane, podaj swoje nazwisko" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.pesel"
              label="Pesel"
              validation_msg={errors?.customer?.pesel?.message}
              required={true}
              inputProps={{
                ...register("customer.pesel", { required: "Pole jest wymagane, podaj swój PESEL" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.street"
              label="Ulica, nr. domu"
              validation_msg={errors?.customer?.street?.message}
              required={true}
              inputProps={{
                ...register("customer.street", { required: "Pole jest wymagane, podaj swój adres w formacie: ulica, numer domu/mieszkania" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.city"
              label="Miasto"
              validation_msg={errors?.customer?.city?.message}
              required={true}
              inputProps={{
                ...register("customer.city", { required: "Pole obowiązkowe, podaj miasto w którym mieszkasz" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.postalCode"
              label="Kod pocztowy"
              validation_msg={errors?.customer?.postalCode?.message}
              required={true}
              inputProps={{
                ...register("customer.postalCode", { required: "Pole obowiązkowe, podaj kod pocztowy" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.phone"
              label="Telefon kontaktowy"
              validation_msg={errors?.customer?.phone?.message}
              inputProps={{
                ...register("customer.phone"),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.email"
              label="Adres email"
              validation_msg={errors?.customer?.email?.message}
              inputProps={{
                type: "email",
                ...register("customer.email"),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.wzrost"
              label="Wzrost [cm]"
              validation_msg={errors?.customer?.wzrost?.message}
              required={true}
              inputProps={{
                ...register("customer.wzrost", { required: "Pole obowiązkowe, podaj wzrost" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.waga"
              label="Waga [kg]"
              validation_msg={errors?.customer?.waga?.message}
              required={true}
              inputProps={{
                ...register("customer.waga", { required: "Pole obowiązkowe, podaj wagę" }),
              }}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <TextInput
              id="customer.papierosy"
              label="Czy pali Pan/Pani papierosy?"
              validation_msg={errors?.customer?.papierosy?.message}
              required={true}
              inputProps={{
                placeholder: 'TAK/NIE',
                ...register("customer.papierosy", { required: "Pole obowiązkowe" }),
              }}
            />
          </Col>
          {/* <Col size={[4, 4, 12, 12]}>
              <TextInput
                id="prescriptions[0].name"
                label="Pesel"
                validation_msg={errors?.prescriptions?.[0]?.name?.message || undefined}
                required={true}
                inputProps={{
                  ...register("prescriptions.[0].name", { required: "Pole jest wymagane, podaj swój PESEL" }),
                }}
              />
            </Col> */}
          <Col size={12}>
            <div className="bg-light">
              <h3>Lista wnioskowanych leków</h3>
              {fields.map((prescription, index) => (
                <Row gap={8} key={prescription.id} className="prescriptions-row">
                  <Col size={[6, 6, 6, 6]}>
                    <TextInput
                      id={`prescriptions[${index}].name`}
                      label="Nazwa leku"
                      validation_msg={errors?.prescriptions?.[index]?.name?.message}
                      required={true}
                      inputProps={{
                        ...register(`prescriptions.${index}.name`, {
                          required: "Pole obowiązkowe, podaj nazwę leku",
                        }),
                      }}
                    />
                  </Col>
                  <Col size={[5, 5, 6, 6]}>
                    <TextInput
                      id={`prescriptions[${index}].dose`}
                      label="Dawka"
                      validation_msg={errors?.prescriptions?.[index]?.dose?.message}
                      required={true}
                      inputProps={{
                        ...register(`prescriptions.${index}.dose`, {
                          required: "Pole obowiązkowe, podaj dawkę recepty",
                        }),
                      }}
                    />
                  </Col>
                  <Col size={[6, 6, 6, 6]}>
                    <TextInput
                      id={`prescriptions[${index}].pillsCountInPackage`}
                      label="Liczba tabletek w opakowaniu"
                      validation_msg={errors?.prescriptions?.[index]?.pillsCountInPackage?.message}
                      required={true}
                      inputProps={{
                        ...register(`prescriptions.${index}.pillsCountInPackage`, {
                          required: "Pole obowiązkowe, podaj liczbę tabletek w opakowaniu",
                        }),
                      }}
                    />
                  </Col>
                  <Col size={[5, 5, 5, 5]}>
                    <TextInput
                      id={`prescriptions[${index}].packagesCount`}
                      label="Liczba opakowań"
                      validation_msg={errors?.prescriptions?.[index]?.packagesCount?.message}
                      required={true}
                      inputProps={{
                        ...register(`prescriptions.${index}.packagesCount`, {
                          required: "Pole obowiązkowe, podaj liczbę opakowań",
                        }),
                      }}
                    />
                  </Col>

                  <Col size={[1, 1, 1, 1]} className="text-center">
                    <b className="show-on-mobile">Usuń lek&nbsp;</b>
                    <button className="btn-material-cancel" type="button" onClick={() => remove(index)} disabled={index === 0}>
                      X
                    </button>
                  </Col>
                </Row>
              ))}
              <Row gap={8}>
                <Col size={[2, 4, 6, 12]} offset={[5, 4, 3, 0]}>
                  <button disabled={prescriptions?.length > 15} className="btn-material-nav" type="button" onClick={() => append({ name: "", dose: "", pillsCountInPackage: "", packagesCount: "" })}>
                    {prescriptions?.length > 0 ? "Dodaj kolejny lek" : "Dodaj lek"}
                  </button>
                </Col>
              </Row>
            </div>
          </Col>

          {/* <TextArea
          ariaLabel="Pole obowiązkowe, podaj nazwy leków wraz z dawką oraz dawkowaniem"
          title="Nazwy leków wraz z dawką oraz dawkowaniem* / Skierowanie do specjalisty"
          name="message"
          placeholder="Nazwy leków wraz z dawką oraz dawkowaniem* / Skierowanie do specjalisty - powód wystawienia skierowania"
          val={formState.message || ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setInputValue(e)
            setAlertsActive(prev => ({ ...prev, message: false }))
          }}
          required
          rows={12}
          alert={alertsActive.message}
        /> */}
        </Row>
        <Row gap={16}>
          <Col size={[6, 6, 12, 12]}>
            <CheckBoxInput
              id="customer.isAcceptedNoProblems"
              label="Oświadczenie"
              validation_msg={errors?.customer?.isAcceptedNoProblems?.message}
              inputProps={{
                ...register("customer.isAcceptedNoProblems", {
                  required: "Pole wymagane",
                }),
              }}
              description={"Oświadczam, że nie mam żadnych dolegliwości i nie wymagam osobistej konsultacji lekarskiej oraz nie przebywam w żadnym oddziale szpitalnym *"}
            />
          </Col>
          <Col size={[6, 6, 12, 12]}>
            <CheckBoxInput
              id="customer.isAcceptedRodo"
              label="Oświadczenie"
              validation_msg={errors?.customer?.isAcceptedRodo?.message}
              inputProps={{
                ...register("customer.isAcceptedRodo", {
                  required: "Pole wymagane",
                }),
              }}
              description={
                <>
                  Oświadczam, że zapoznałem/łam się z{" "}
                  <a href={"./do-pobrania/Klauzula-informacyjna-Elektroniczy-wniosek-o-recepte.pdf"} target="_blank" rel="noopener noreferrer">
                    klauzulą RODO
                  </a>
                  *
                </>
              }
            />
          </Col>
        </Row>

        <Row gap={16}>
          <Col size={[6, 6, 12, 12]}>
            {!isAcceptedSmsRecive ? (
              <CheckBoxInput
                id="customer.isAcceptedSelfRecive"
                label="Oświadczenie"
                required
                validation_msg={errors?.customer?.isAcceptedSelfRecive?.message}
                inputProps={{
                  disabled: isAcceptedSmsRecive,
                  ...register("customer.isAcceptedSelfRecive", {
                    required: "Pole wymagane",
                  }),
                }}
                description={"Nie posiadam Konta Pacjenta (IKP) - odbiór kodu e-recepty odbywa się osobiście lub przez osobę upoważnioną w ciągu miesiąca od daty wystawienia recepty w Przychodni SPZOZ w Sycowie *"}
              />
            ) : null}
          </Col>
          <Col size={[6, 6, 12, 12]}>
            {!isAcceptedSelfRecive ? (
              <CheckBoxInput
                id="customer.isAcceptedSmsRecive"
                label="Oświadczenie"
                validation_msg={errors?.customer?.isAcceptedSmsRecive?.message}
                required={!isAcceptedSelfRecive}
                inputProps={{
                  disabled: isAcceptedSelfRecive,
                  ...register(
                    "customer.isAcceptedSmsRecive",
                    isAcceptedSelfRecive
                      ? undefined
                      : {
                          required: isAcceptedSelfRecive ? false : "Pole wymagane",
                        }
                  ),
                }}
                description={"Oświadczam, że posiadam Konto Pacjenta (IKP) - po odbiór leków z kodem e-recepty otrzymanym przez sms lub email należy zgłosić się bezpośrednio do apteki (bez konieczności wizyty w przychodni) *"}
              />
            ) : null}
          </Col>
        </Row>
        <br />
        <Row gap={16}>
          <Col size={12}>
            <button type="submit" className="btn-material-nav">
              Wyślij wniosek
            </button>
          </Col>
        </Row>
      </form>
      <br />
      {/* <p className={`validator${validationActive ? ' isActive' : ''}`}>
      Nie można wysłać formularza - uzupełnij wszystkie pola z gwiazdką.
    </p>
    <p className={`callback${callbackActive ? ' isActive' : ''}`}>
      Wypełniono poprawnie. Proszę czekać trwa wysyłanie formularza...
    </p> */}
      <br />
      <br />
      {/* 
    <div className="btnWrapper" >
      <div  tabIndex={0}>
        <LinkBtn color="blue" >
          
        </LinkBtn>
      </div>
    </div> */}
    </div>
  );
};

export default PrescriptionsForm;
