import React from "react";
import { RoutePath } from "../../../route-paths";
// import PaszportImg from "../../assets/paszport-covidowy.png";
import OgolnaImg from "../../assets/poradnia-ogolna.svg";
import UrologImg from "../../assets/urolog-2022.png";
// import InfoImg from "../../assets/info.png";
// import DarmoweImg from "../../assets/darmowe-szczepienie.png";
import WniosekReceptaImg from "../../assets/wniosek-o-recepte.png";
// import ZmianyImg from "../../assets/zmiany-w-terminarzu.png";
import StandardyImg from "../../assets/Standardy-Organizacyjne-SPZOZ.png";
// import COVIDImg from "../../assets/covid-co-robic.jpg";
import RtgImg1 from "../../assets/rtg-przychodnia.png";
import RtgImg2 from "../../assets/rtg-szpital.png";
import CovidkidsImg from "../../assets/szczepienie-covid-dzieci.png";
import DorosliImg from "../../assets/szczepienie-dorosłych.png";
import DostepnoscImg from "../../assets/dostepnosc.png";
import PomocImg from "../../assets/bezplatna-pomoc-ukraina.png";
import ProgramImg from "../../assets/program-35-plus.png";
import Program24Img from "../../assets/profilaktyka-24-plus.png";
import SzczepieniaPlusImg from "../../assets/szczepienia-60-plus.png";
import CzterdziesciImg from "../../assets/profilaktyka-40-plus.png";
import C19Img from "../../assets/informacja-punkt-szczepien-covid.png";
import UeImg from "../../assets/granty.png";
import KodImg from "../../assets/kod.png";
import ZbiorkaImg from "../../assets/zbiorka.png";
import PlakatImg from "../../assets/plakat.png";
import OgloszenieImg from "../../assets/ogloszenie-przebudowa.png";
import NoweImg from "../../assets/nowe.png";
import ZyczeniaImg from "../../assets/SPZOZ_zyczenia_2023-01.png";
import MlodziezImg from "../../assets/plakat-mlodziez.png";
import WielkanocImg from "../../assets/wesolych_SPZOZ.png";
import PiknikImg from "../../assets/piknik.png";
import MammoImg from "../../assets/Plakat-mammografia.jpg";
import KrtanImg from "../../assets/badanie-krtani.png";
import CdlImg from "../../assets/CDL.png";
import SzczImg from "../../assets/szcz.png";


export const news_data = [
  // {
  //   title: "Harmonogram szczepień COVID",
  //   img: CovidImg,
  //   to: undefined,
  //   isClassicLink: undefined,
  //   alt: "Harmonogram szczepień COVID",
  //   content: (
    //     <>
    //       W dniach: <strong>3.01, 4.01, 5.01, 7.01, 10.01, 11.01, 12.01, 13.01, 14.01</strong> szczepienia COVID będą wykonywane w godzinach od 7.00 do 14.00. Więcej informacji można uzyskać pod numerem telefonu <a href="tel:574368425"><strong>574 368 425</strong></a>. Następne terminy szczepień zostaną podane w późniejszym terminie.
    //     </>
    //   ),
    // },
    {
      title: "Harmonogram pracy lekarzy",
      lang: "pl",
      img: OgolnaImg,
      to: RoutePath.BASICHEALTHCARE,
      isClassicLink: undefined,
      alt: "Tabelaryczne zestawienie harmonogramu pracy lekarzy",
      content: <>Nowy harmonogram prac Lekarzy POZ, który będzie obowiązywać w bieżącym tygodniu.</>,
    },
    {
      title: "UROLOG - kwiecień 2025",
      lang: "pl",
      img: UrologImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Terminy do Urologa na 2024 rok",
      content: <>Zapraszamy do umawiania terminów do Urologa w kwietniu 2025 roku. Terminy przyjęć to 7.04.2025 roku od 15:00 do 18:00 w gabinecie 125 oraz 28.04.2025 roku od 15:00 do 18.00 w gabinecie 125. Zapisy w rejestracji ogólnej lub pod numerami telefonu: <strong><a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145">62 785 51 45</a>,<a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">62 785 51 92</a>,<a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a> </strong>. Koszt wizyty wraz z USG to 200 zł.</>,
    },
    {
      title: "Bezpłatne szczepienia COVID-19 dla dzieci",
      lang: "pl",
      img: C19Img,
      to: undefined,
      isClassicLink: undefined,
      alt: "Bezpłatne szczepienia COVID-19 dla dzieci",
      content: <><strong>Szczepienie jest realizowane szczepionką przeznaczoną dla dzieci w wieku 6 miesięcy -11 lat o nazwie Spikevax JN.1 w dawce 0,25 ml (50 mikrogramów mRNA) w poniższych schematach:</strong><br />
      <ul>
        <li>u dzieci dotychczas nieszczepionych w wieku od 6 miesięcy do 4 lat podawane są dwie dawki szczepionki po 0,25 ml, w odstępie co najmniej 28 dni. Dzieci szczepione w ubiegłych latach przeciw COVID-19 powinny otrzymać jedną dawkę szczepionki 0,25 ml.
        </li>
        <li>u dzieci w wieku od 5 lat do 11 lat, po uprzednim szczepieniu (szczepienie przypominające) lub bez uprzedniego szczepienia podawana jest jedna dawka szczepionki 0,25 ml.</li>
        </ul><br />
        <strong>Szczepienie zalecane jest szczególnie dzieciom o największym ryzyku przebiegu ciężkiego COVID-19, do których należą dzieci z chorobami współistniejącymi  zwiększającymi ryzyko ciężkiego przebiegu COVID-19, tj. otyłością (BMI≥25), cukrzycą, przewlekłą chorobą płuc, przewlekłą chorobą nerek, chorobą układu sercowo-naczyniowego, zaburzeniami neurorozwojowymi, aktywną chorobą nowotworową, chorobą immunosupresyjną lub leczeniem immunosupresyjnym.</strong></>,
    },
    {
      title: "Realizacja szczepin przeciw COVID-19",
      lang: "pl",
      img: SzczImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Realizacja szczepin przeciw COVID-19",
      content: <>Realizacja szczepin przeciw COVID-19 w sezonie 2024/2025.</>,
    },
    {
      title: "Przerwa Świąteczna - Centrum Diagnostyki Laboratoryjnej",
      lang: "pl",
      img: CdlImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Przerwa Świąteczna - Centrum Diagnostyki Laboratoryjnej",
      content: <>W związku ze zbliżającymi się Świętami Boego Narodzenia informujemy, że w dniu 24 Grudnia oraz 27 Grudnia Laboratorium Centrum Diagnostyki Laboratoryjnej Sp. z o.o. będzie nieczynne. Jednocześnie chcemy poinformować, że w dniach 31 Grudnia jak i również bezpośrednio po Nowym Roku Laboratorium pracuje normalnie i harmonogram odbioru materiału nie ulega zmianie.</>,
    },
    {
      title: "Zadbaj o swój głos - bezpłatne badanie krtani, gardła i szyi w Białą Sobotę",
      img: KrtanImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Plakat Zadbaj o swój głos - bezpłatne badanie krtani, gardła i szyi w Białą Sobotę",
      content: <><p>Zadbaj o swój głos - bezpłatne badanie krtani, gardła i szyi w Białą Sobotę</p>
      <p><strong>23.11.2024 r. godz. 9.00 - 13.00 w SPZOZ „Przychodnia” w Sycowie przy ul. Wrocławskiej 2</strong></p>
      <br />
      <p>Specjaliści laryngolodzy będą badać metodą fiberoendoskopii gardła i krtani oraz metodą ultrasonografii szyi. Zmiana głosu? Nie czekaj - działaj! Guzek szyi? Nie czekaj - działaj! </p>
      <br /> Specjaliści laryngolodzy z Wojewódzkiego Szpitala Specjalistycznego we Wrocławiu:
      <ul>
        <li>Prof. dr hab. Małgorzata Wierzbicka</li>
        <li>Dr n. med. Hanna Klimza</li>
        <li>Dr Paweł Golach</li>
        <li>Dr Dorota Świątek</li>
      </ul></>,
    },
    {
      title: "Badania Mammograficzne",
      img: MammoImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Plakat Badania Mammograficzne",
      content: <>Zapraszamy na bezpłatne badania mammograficzzne w ramach programu Profilaktyka Raka Piersi refundowanego przez NFZ.</>,
    },
    {
      title: "Piknik Zdrowia!",
      img: PiknikImg,
      to: RoutePath.BASICHEALTHCARE,
      isClassicLink: undefined,
      alt: "Zapraszamy na Piknik Zdrowia już 6.10.2024 roku od godziny 9.00 do 14.00.",
      content: <>Zapraszamy na Piknik Zdrowia już 6.10.2024 roku od godziny 9.00 do 14.00.</>,
    },
    {
      title: "Informacja – Centrum Diagnostyki Laboratoryjnej",
      lang: "pl",
      img: C19Img,
      to: undefined,
      isClassicLink: undefined,
      alt: "Informacja – Centrum Diagnostyki Laboratoryjnej",
      content: <>W związku ze zbliżającymi się świętami 1 i 3 maja informujemy, że w dniu 02.05.2024 r. (czwartek) Punkt Pobrań w SPZOZ Przychodnia w Sycowie, będzie nieczynny. Zapraszamy ponownie od 4 maja 2024 r.</>,
    },
    {
      title: "Wesołych Świąt!",
      lang: "pl",
      img: WielkanocImg,
      to: RoutePath.BASICHEALTHCARE,
      isClassicLink: undefined,
      alt: "Życzenia Wielkanocne od Dyrekcji SPZOZ wraz z pracownikami",
      content: <></>,
    },
    {
      title: "Mały pacjencie, jesteś dla nas ważny!",
      lang: "pl",
      img: MlodziezImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Mały pacjencie, jesteś dla nas ważny!",
      content: <>Mały pacjencie, jesteś dla nas ważny!</>,
    },
    {
      title: "Życzenia Świąteczne",
      lang: "pl",
      img: ZyczeniaImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Życzenia Świąteczne",
      content: <></>,
    },
    {
      title: "Szczepienia przeciw COVID-19",
      lang: "pl",
      img: NoweImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Szczepienia przeciw COVID-19",
      content: <><strong>Od 6 grudnia 2023 r. szczepimy szczepionką NAUAXOVID</strong> zaktualizowaną dla podwariantu XBB.1.5 Szczepionkę Nauaxovid może przyjąć osoba, która ukończyła 12 lat i:
      <ul>
        <li>wcześniej nie szczepiła się przeciw Covid-19,</li>
        <li>szczepiła się przeciw Covid-19, ale od ostatniego szczepienia upłynęło co najmniej 6-mcy</li>
      </ul></>,
    },
    {
      title: "Uwaga Pacjenci!",
      lang: "pl",
      img: OgloszenieImg,
      to: undefined,
      isClassicLink: undefined,
      alt: "Tabelaryczne zestawienie harmonogramu pracy lekarzy",
      content: <>W związku z tym, że <strong>SPZOZ jest czynny do godziny 18.00</strong> nie ma możliwości wieczornego  podania zleconych iniekcji lub wlewów kroplowych w środowisku domowym, zachowując 12 godzinny odstęp. <strong>O podanie wieczorne należy zadbać we własnym zakresie.</strong></>,
    },
    {
      title: "UWAGA! Poradnia Ortodontyczna będzie nieczynna",
      lang: "pl",
      img: C19Img,
      to: undefined,
      isClassicLink: undefined,
      alt: "UWAGA! Poradnia Ortodontyczna będzie nieczynna",
      content: <><strong>W dniach 29.08.2023 – 04.09.2023</strong> Poradnia Ortodontyczna będzie nieczynna.</>,
  },
    {
      title: "UWAGA! Poradnia Dermatologiczna będzie nieczynna",
      lang: "pl",
      img: C19Img,
      to: undefined,
      isClassicLink: undefined,
      alt: "UWAGA! Poradnia Dermatologiczna będzie nieczynna",
      content: <>Poradnia Dermatologiczna <strong>w dniach od 28.08.2023 – 04.09.2023 będzie nieczynna</strong>.</>,
  },
    {
      title: "Profilaktyka 40+",
      lang: "pl",
      img: C19Img,
      to: undefined,
      isClassicLink: undefined,
      alt: "Profilaktyka 40+ ",
      content: <>Kto może skorzystać z badań
      <p>Program skierowany jest do pacjentów, którzy:</p>
      <ul>
        <li>skończyli 40 lat</li>
        <li>nie korzystali wcześniej z programu Profilaktyka 40 PLUS </li>
        <li>lub skorzystali z niego, ale minęło co najmniej 12 miesięcy od pierwszych badań w ramach programu</li>
      </ul>
      <p>Informację o programie profilaktycznym można uzyskać w:</p>
       <ul>
        <li>Gabinecie Zabiegowym 129 I piętro, nr. telefonu: 62 785 5195;</li>
        <li>Rejestracji Ogólnej, nr. telefonu: 62 785 5145, 62 785 5192</li>
        <li>pod nr telefonu: 531 855 237 oraz 574 368 425</li>
      </ul>
      <p>Ankiety do pobrania w Rejestracji Ogólnej w SPZOZ „Przychodnia” w Sycowie</p></>,
  },
  {
      title: "UWAGA! Poradnia Medycyny Pracy będzie nieczynna",
      lang: "pl",
      img: C19Img,
      to: undefined,
      isClassicLink: undefined,
      alt: "UWAGA! Poradnia Medycyny Pracy będzie nieczynna",
      content: <>Uwaga! <strong>W dniach od 24.07.2023 – 28.07.2023 Poradnia Medycyny Pracy będzie nieczynna</strong></>,
  },
  {
      title: "UWAGA! Poradnia Okulistyczna będzie nieczynna",
      lang: "pl",
      img: C19Img,
      to: undefined,
      isClassicLink: undefined,
      alt: "UWAGA! Poradnia Okulistyczna będzie nieczynna",
      content: <>Uwaga! <strong>Poradnia Okulistyczna będzie nieczynna w dniach 3.07.2023 - 14.07.2023 roku.</strong></>,
  },
  {
    title: "Idziesz do lekarza? NIP pracodawcy nadal potrzebny",
    lang: "pl",
    img: C19Img,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja na temat zwolnienia lekarskiego",
    content: <>Wejście w życie systemu Elektronicznej Weryfikacji Uprawnień Świadczeniobiorców (eWUŚ), nie oznacza, że u lekarza nie musimy podawać NIP-u pracodawcy. <strong>Numer ten jest nadal konieczny, by lekarz wypisał zwolnienie.</strong></>,
  },
  {
    title: "UWAGA! Zbiórka pieniędzy na leczenie dla chłopców Leona i Łukasza",
    lang: "pl",
    img: ZbiorkaImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "UWAGA! Zbiórka pieniędzy na leczenie dla chłopców Leona i Łukasza",
    content: <>Uwaga! <strong>W SPZOZ Przychodnia w Sycowie trwa zbiórka pieniędzy na leczenie dla chłopców Leona i Łukasza. Więcej informacji uzyskają Państwo w Rejestracji na I piętrze.</strong></>,
  },
  {
    title: "Bezpieczni teraz - bezpieczni w przyszłości",
    lang: "pl",
    img: PlakatImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Bezpieczni teraz - bezpieczni w przyszłości",
    content: <>Jeśli Twoja córka lub syn ma 12 - 13 lat, zaszczep ich <strong>BEZPŁATNIE</strong> przeciw wirusowi <strong>HPV</strong></>,
  },
  {
    title: "Informacja dotycząca szczepieniu przeciw wirusowi brodawczaka ludzkiego (HPV)",
    lang: "pl",
    img: C19Img,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja dotycząca szczepieniu przeciw wirusowi brodawczaka ludzkiego (HPV)",
    content: <>Szczepienia przeprowadzane będą u dziewczynek i chłopców w grupach wiekowych <strong>12 i 13 lat</strong>. W 2023 r. będą dotyczyły roczników <strong>2010 i 2011</strong>. Szczepienia wykonujemy szczepionkami:
    <ul>
      <li>Cervarix</li>
      <li>Gardasil 9</li>
    </ul>
    <p>Schemat szczepienia jest 2-dawkowy. Rekomendowany odstęp podania drugiej dawki 6 -12 m-cy. Szczepienia są bezpłatne i dobrowolne, zapraszamy do zapisywania się  na szczepienie HPV do Poradni Dziecięcej w SPZOZ „Przychodnia” w Sycowie. Telefon do punktu szczepień <a aria-label="Pod numerem telefonu 62 606 69 81" href="tel:626066981">62 606 69 81</a></p></>,
  },
  {
    title: "Informacja – Centrum Diagnostyki Laboratoryjnej",
    lang: "pl",
    img: C19Img,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja – Centrum Diagnostyki Laboratoryjnej",
    content: <>W związku ze zbliżającymi się świętami 1 i 3 maja informujemy, że w dniu 02.05.2022 r. (wtorek) Punkt Pobrań w SPZOZ Przychodnia w Sycowie, będzie nieczynny. Zapraszamy ponownie od 4 maja 2023 r.</>,
  },
  {
    title: "Kod recepty",
    lang: "pl",
    img: KodImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Kod recepty poprzez SMS",
    content: <><strong>KOD recepty i skierowania</strong> nie będzie już wysyłany sms-em na telefon. Usługa powiadamiania sms została wyłączona. Aby uzyskać kod do recepty / skierowania należy zadzwonić:<strong><a aria-label="Pod numerem telefonu 62 785 51 96" href="tel:627855196">62 785 51 96</a>,<a aria-label="Pod numerem telefonu 62 785 51 95" href="tel:627855195">62 785 51 95</a></strong>, zgłosić się do Przychodni lub posiadać Internetowe Konto Pacjenta (IKP).</>,
  },
  {
    title: "Jesteśmy Grantobiorcą w projekcie UE",
    lang: "pl",
    img: UeImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Terminy do Urologa na 2023 rok",
    content: <>Jesteśmy Grantobiorcą w projekcie pn.: „Wdrożenie e-usług w placówkach POZ i ich integracja z systemem e-zdrowia” („e-usługi POZ”), finansowanym z Funduszy Europejskich. Nasza placówka otrzymała Grant na rozbudowę systemu gabinetowego oraz zakup sprzętu teleinformatycznego, które umożliwiają tworzenie i udostępnianie elektronicznej dokumentacji medycznej (EDM) oraz świadczenia e-usług. Więcej informacji znajdą Państwo <a aria-label="Informacja o otrzymanym grancie UE DOCX 75 KB" href="./do-pobrania/Informacja na strone www Grantobiorcow w projekcie e-Usługi POZ.docx"><u>tutaj</u></a> </>,
  },
  {
    title: "Firmy Farmaceutyczne",
    lang: "pl",
    img: C19Img,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja dla Firm Farmaceutycznych",
    content: <>Reklamowanie wyrobów medycznych przez firmy farmaceutyczne w SPZOZ „Przychodnia” w Sycowie <strong>odbywa się:</strong> po uprzednim uzyskaniu zgody wystawionej przez dyrektora Przychodni oraz po uzgodnieniu terminu spotkania, które powinno odbywać się poza godzinami pracy lekarzy lub osób wykonujących zawód medyczny.</>,
  },
  {
    title: "Punkt Szczepień Covid",
    lang: "pl",
    img: C19Img,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja o dostępności szczepionek w punckie szczepień przecis CIVID-19",
    content: <>W PUNKCIE SZCZEPIEŃ przeciw COVID-19 są dostępne szczepionki:
    <ul>
      <li>Pfizer Omicron BA4-5</li>
      <li>Moderna Omicron</li>
      <li>Johnson & Johnson</li>
      </ul>
      <p>W punkcie szczepień w Poradni Dziecięcej jest dostępna szczepionka Covid dla dzieci:</p>
      <ul>
        <li>Pfizer Junior 6 m do 4 lata</li></ul></>,
  },
  {
    title: "Profilaktyka 40+",
    lang: "pl",
    img: CzterdziesciImg,
    to: "https://www.gov.pl/profilaktyka",
    isClassicLink: true,
    alt: "Informacje o programie Profilaktyka 40 plus",
    content: <>Zapraszamy do zapoznania się z informacjami na temat programu Profilaktyka 40+.</>,
  },
  {
    title: "Szczepienia 60+",
    lang: "pl",
    img: SzczepieniaPlusImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja na temat programu Szczepienia 60 plus",
    content: <>Od 22.07.2022 r. rozpoczyna się szczepienie <strong>osób w wieku 60+ czwartą dawką przypominającą.</strong> Szczepimy w <strong>Punkcie Szczepień (wejście od strony parkingu za budynkiem Przychodni) od poniedziałku do piatku od 7:30 do 12:00.</strong> W celu dodatkowych informacji prosimy o kontakt telefoniczny: <a aria-label="Pod numerem telefonu 574 368 425" href="tel:574368425">574 368 425</a> lub <a aria-label="Pod numerem telefonu 62 785 51 95" href="tel:627855195">62 785 51 95</a></>,
  },

  {
    title: "Program 24+ POZ PLUS",
    lang: "pl",
    img: Program24Img,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja na temat Programu 24 plus POZ PLUS",
    content: <>Zadbaj o swoje zdrowie i wyprzedź chorobę. <strong>Darmowa profilaktyka dla osób 24+ zadeklarowanych w SPZOZ „Przychodnia”.</strong> Nie zwlekaj? Już dziś zgłoś się do programu POZ PLUS, a otrzymasz:
    <ul>
      <li>aktywną opiekę w zdrowiu i chorobie,</li>
      <li>bezpłatny bilans zdrowia,</li>
      <li>opiekę koordynatora.</li>
    </ul>
    <p>Program obejmuje:</p>
    <ul>
      <li>wypełnienie kwestionariusza bilansowego,</li>
      <li>wykonanie badań laboratoryjnych i diagnostycznych,</li>
      <li>wizytę u lekarza POZ,</li>
      <li>opiekę koordynatora,</li>
      <li>konsultacje specjalistyczne (jeśli zachodzi taka potrzeba),</li>
      <li>priorytetowe umawianie wizyt.</li>
    </ul>
    <p>Wystarczy, że zgłosisz się do koordynatora w gabinecie nr 9 lub zadzwonisz pod numer: <a aria-label="Pod numerem telefonu 531 855 237" href="tel:531855237">531 855 237</a>.</p></>,
  },
  {
    title: "Program 35+",
    lang: "pl",
    img: ProgramImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Informacja na temat Programu 35 plus",
    content: <>Zapraszmy na <strong>bezpłatne badania profilaktyczne chorób układu krążenia.</strong> Badania przeznaczone są dla osób:
    <ul>
      <li>w wieku 35 – 65 lat zadeklarowanych w naszej Przychodni</li>
      <li>u których w ciągu ostatnich 5 lat nie została rozpoznana choroba układu krążenia, cukrzyca, przewlekła choroba nerek
    lub rodzinna hipercholesterolemia</li>
      <li>którzy nie korzystali ze świadczeń udzielanych w ramach profilaktyki chorób układu krążenia</li>
    </ul>
    <p>Szczegółowe informacje: telefonicznie <a aria-label="Pod numerem telefonu 531 855 237" href="tel:531855237">531 855 237</a> lub osobiście w gab. 9 w Przychodni w Sycowie</p></>,
  },
  {
    title: "SPZOZ Przychodnia w Sycowie zaprasza uchodźców z Ukrainy na szczepienia Covid.",
    lang: "pl",
    img: PomocImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Flaga Ukrainy",
    content: <><strong>Szczepienia dla dorosłych</strong> odbywają się od poniedziałku do piątku w godz. 8.00-14.00 w gabinecie zabiegowym 129 (I piętro).<br /><strong>Szczepienia dla dzieci od lat 5</strong> w Poradni Dziecięcej we wtorki i piątki w godz. 8.00-12.00. Telefon kontaktowy: <strong><a aria-label="Pod numerem telefonu 574 368 425" href="tel:574368425">574 368 425</a></strong></>,
  },
  {
    title: "SPZOZ Поліклініка в Сицові повідомляє,",
    lang: "uk",
    img: PomocImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Flaga Ukrainy",
    content: <>SPZOZ Поліклініка в Сицові повідомляє,
    що існує можливість безкоштовної медичної  допомоги біженцям з України. Надаємо допомогу: терапевта, педіатра, ларинголога і акушера-гінеколога. Звертатись за телефонами: <strong><a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145">62 785 51 45</a>, <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">62 785 51 92</a>, <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a></strong></>,
  },
  {
    title: "Bezpłatna pomoc medyczna dla Uchodźców z Ukrainy",
    lang: "pl",
    img: PomocImg,
    to: undefined,
    isClassicLink: undefined,
    alt: "Flaga Ukrainy",
    content: <>SPZOZ Przychodnia w Sycowie informuje, że istnieje możliwość bezpłatnej pomocy medycznej dla uchodźców z Ukrainy. Świadczymy pomoc w zakresie medycyny internistycznej, pediatrii, laryngologii oraz położniczo-ginekologicznej. Kontakt pod numerami telefonów: 
     <strong><a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145"> 62 785 51 45</a>, <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">62 785 51 92</a>, <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a></strong></>,
  },
  {
    title: "Dostępność Plus",
    lang: "pl",
    img: DostepnoscImg,
    isClassicLink: undefined,
    alt: "Logo Program Dostępność Plus Logo Ministerstwa Zdrowia",
    content: <>Samodzielny Publiczny Zakład Opieki Zdrowotnej „Przychodnia” w Sycowie jest grantobiorcą projektu pn. Dostępność Plus dla Zdrowia, który finansowany jest z Funduszy Europejskich. Więcej informacji znajdą Państwo <a aria-label="Informacje dostępność plus DOC 1399 KB" href="./do-pobrania/informacja-dostepnosc-plus.docx"><u>tutaj</u></a> </>,
  },
  {
    title: "Szczepienia dzieci od lat 5",
    lang: "pl",
    img: CovidkidsImg,
    to: undefined,
    isClassicLink: undefined,
    alt: undefined,
    content: <>Szczepienie dzieci odbywa się w Poradni Dziecięceh w Punkcie Szczepień we wtorki i piątki od 8:00 do 12:00. Telefon kontaktowy <strong><a aria-label="Pod numerem telefonu 574 368 425" href="tel:574368425">574 368 425</a></strong>. Do punktu szczepień covid wchodzi tylko jeden opiekun dziecka. Prosimy, jeśli jest taka możliwość przychodzić z wypełnionym kwestionariuszem do szczepienia - do pobrania <strong><u><a aria-label="Kwestionariusz wstępnego wywiadu PDF 215KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/covid/kwestionariusz-logo-grupa-5-11 lat_91221.pdf">tutaj.</a></u></strong></>,
  },
  {
    title: "Szczepienia osób dorosłych",
    lang: "pl",
    img: DorosliImg,
    to: undefined,
    isClassicLink: undefined,
    alt: undefined,
    content: <>Szczepienie dorosłych: Punkt Szczepień został <strong>przeniesiony do gabinetu zabiegowego nr 129 na I piętrze</strong>. Szczepimy od 8:00 do 14:00 od poniedziałku do piątku. Telefon do Punktu szczepień Covid <strong><u><a  aria-label="Pod numerem telefonu 574 368 425" href="tel:574368425">574 368 425</a></u></strong></>,
  },
  {
    title: "RTG Przychodnia w Sycowie",
    lang: "pl",
    img: RtgImg1,
    to: undefined,
    alt: "Zdjęcie RTG dłoni",
    isClassicLink: undefined,
    content: (
      <>
        Czynny od poniedziałku do piątku od 8:00 do 13:00 i od 14:30 do 16:30. Numer telefonu <a aria-label="Pod numerem telefonu 62 785 51 93" href="tel:627855193">62 785 51 93</a>.
      </>
    ),
  },
  {
    title: "RTG w Szpitalu w Sycowie",
    lang: "pl",
    img: RtgImg2,
    to: undefined,
    alt: "Zdjęcie RTG dłoni",
    isClassicLink: undefined,
    content: (
      <>
        ul. Oleśnicka 25. Czynny w poniedziałek i wtorek od 7:00 do 14:30, w środę od 10:30 do 17:30, w czwartek i piątek od 7:00 do 14:30. Numer telefonu <a aria-label="Pod numerem telefonu 62 606 69 62" href="tel:626066962">62 606 69 62</a>.
      </>
    ),
  },
  // {
  //   title: "COVID 19 - Co robić?",
  //   lang: "pl",
  //   img: COVIDImg,
  //   to: "https://wroclaw.tvp.pl/53198885/covid19-co-robic?fbclid=IwAR20KhTDJMp_-Y0da6kyQ74RA0QgIGH5F3B69RdTy5bEQz2AqJAWyZazlg4",
  //   isClassicLink: true,
  //   alt: undefined,
  //   content: <>Prosimy o zapoznanie się z instruktażowym filmikiem dotyczącycm COVID-19, jej diagnozowania, profilaktyki oraz procesu szczepień.</>,
  // },
  {
    title: "Standardy Organizacyjne SPZOZ",
    lang: "pl",
    img: StandardyImg,
    to: "./do-pobrania/Standardy-Organizacyjne-SPZOZ.pdf",
    isClassicLink: true,
    alt: undefined,
    content: <>Standardy Organizacyjne Samodzielnego Publicznego Zakładu Opieki Zdrowotnej Przychodnia w Sycowie.</>,
  },
  {
    title: "Wniosek o e-Receptę",
    lang: "pl",
    img: WniosekReceptaImg,
    to: RoutePath.PRESCRIPTIONS,
    isClassicLink: undefined,
    alt: "Kobieta pisząca na telefonie komórkowym ",
    content: <>Samodzielny Publiczny Zakład Opieki Zdrowotnej “Przychodnia” w Sycowie informuje, iż w celu ograniczenia kontaktu między pacjentami w Przychodni KOD WYSTAWIONEJ RECEPTY można uzyskać telefonicznie.</>,
  },
];
